<template>
  <div class="client-payment-history">
    <data-table
      action="outboundPayments/get"
      :headers="headers"
      ref="table"
      :limit="50"
      :filters.sync="filters"
    >
      <template #cell(amount)="{ item }">
        {{ formatCurrency(item.final_amount, item.currency.code) }}
      </template>

      <template #cell(adjustment_amount)="{ item }">
        {{ formatCurrency(item.adjustment_amount, item.currency.code) }}
      </template>

      <template #cell(commission)="{ item }">
        {{ formatCurrency(item.commission, item.currency.code) }}
      </template>

      <!-- Notes -->
      <template #cell(notes)="{item}">
        <ebp-note-button :text="truncate(item.notes)" />
      </template>

      <template #cell(status)="{ item }">
        <!-- Use statusVariant from @/mixins/colors.js -->
        <h5 class="m-0">
          <b-badge :variant="statusVariant(item.status)" class="mr-1">{{
            $t(item.status)
          }}</b-badge>
        </h5>
      </template>

      <template #cell(method)="{ item }">
        {{ item.payment_method && $t(item.payment_method.name) }}
      </template>

      <template #cell(created_at)="{ item }">
        {{ formatDate(item.created_at) }}
      </template>

      <!-- Actions -->
      <template #actions="{ item }">
        <b-dropdown class="card-drop" variant="white" right toggle-class="p-0">
          <template v-slot:button-content>
            <i class="mdi mdi-dots-horizontal font-size-18"></i>
          </template>
          <b-dropdown-item
            :to="{
              name: 'ClientViewRoyalties',
              query: { outbound_payment_id: item.id }
            }"
            >{{ $t("view-royalties") }}</b-dropdown-item
          >
          <b-dropdown-item @click="exportCSV(item.id)">{{
            $t("export-csv")
          }}</b-dropdown-item>
        </b-dropdown>
      </template>
    </data-table>
  </div>
</template>

<script>
import DataTable from "@/components/data-table";
import royaltySchema from "@/validation-schemas/sale-import";
import royaltyStatuses from "@/fixtures/royalty-statuses";
import EbpNoteButton from "@/components/ebp-note-button";

import truncate from "@/helpers/truncate";
import { mapState } from "vuex";
import { find } from "lodash-es";
import FileDownload from "js-file-download";

export default {
  name: "client-payment-history",
  components: {
    DataTable,
    EbpNoteButton
  },
  data() {
    return {
      royaltyStatuses: royaltyStatuses.map(s => ({
        text: this.$t(s),
        value: s
      })),
      headers: [
        { key: "created_at", label: "date", sortable: true },
        { key: "method", label: "payment-method", sortable: true },
        { key: "amount", sortable: true },
        { key: "commission", sortable: true },
        {
          key: "adjustment_amount",
          lable: "adjustment",
          sortable: true
        },
        { key: "royalties_count", label: "records" },
        "notes",
        "status"
      ],
      editedItem: null,
      saving: false,
      open: false,
      showAddSaleImport: false,
      error: null,
      addTabs: ["import"],
      removing: false,
      filters: {
        user_id: null,
        book_id: null,
        sales_channel_id: null,
        status: null,
        inbound_payment_id: null
      },
      processing: false,
      downloading: false
    };
  },
  mounted() {
    const channel = this.$pusher.subscribe("import-processed");
    channel.bind("import-processed", ({ royalty }) => {
      this.$router.push({
        name: "ViewSaleRecords",
        query: {
          sales_import_id: royalty.id
        }
      });
    });

    this.$watch(
      () => {
        return this.$refs.table.tableData;
      },
      items => {
        this.processing = !!items.filter(i => i.status === "processing").length;
      }
    );
  },
  destroyed() {
    this.$pusher.unsubscribe("import-processed");
  },
  computed: {
    ...mapState("royalties", ["processorTypes"])
  },
  methods: {
    getProcessorName(code) {
      const type = find(this.processorTypes, o => o.code === code);
      return type ? type.name : "";
    },
    truncate,
    added() {
      this.showAddSaleImport = false;
      this.$refs.table.currentPage = 1;
      this.$refs.table.get();
    },
    async exportCSV(id) {
      this.downloading = true;

      try {
        const res = await this.$store.dispatch("reports/exportCSV", {
          outbound_payment_id: id
        });

        FileDownload(res, "sales-by-royalties.csv");
      } catch (err) {
        console.error(err);
      }

      this.downloading = false;
    },
    async remove(royalty) {
      if (
        confirm(
          this.$t("remove-message", {
            name: royalty.user.name
          })
        )
      ) {
        this.removing = royalty.id;
        try {
          await this.$store.dispatch("outboundPayments/delete", royalty.id);
          this.$refs.table.change("id", royalty.id);
        } catch (err) {
          console.error(err);
        }
        this.removing = false;
      }
    },
    edit(royalty) {
      this.editedItem = royalty;
      this.open = true;
    },
    async save() {
      this.error = null;
      const royalty = this.editedItem;
      const validation = royaltySchema.validate(royalty);

      if (validation.error) {
        this.error = validation.error.message;
      } else {
        this.saving = true;
        try {
          await this.$store.dispatch("royalties/update", royalty);
          this.$refs.table.change("id", royalty.id, royalty);
        } catch (err) {
          this.error = this.errorRes(err);
        }
        this.saving = false;
      }
    }
  }
};
</script>

<style></style>
